<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal-main">
        <section class="modals">
          <div class="modal is-active">
            <div class="modal__popup">
              <div class="modal__heading">
                <slot name="title"></slot>

                <button
                  class="btn btn--close"
                  aria-label="Close"
                  @click="close"
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.3096 0.545949C18.5877 -0.182282 17.4197 -0.181684 16.6989 0.545949L9.92393 7.38522L3.15132 0.545949C2.42994 -0.181684 1.26198 -0.182282 0.540595 0.545949C-0.180198 1.27358 -0.180198 2.45322 0.540595 3.18145L7.3138 10.0207L0.540595 16.857C-0.180198 17.5852 -0.180198 18.7643 0.540595 19.4925C0.900695 19.856 1.37333 20.0378 1.84596 20.0378C2.318 20.0378 2.79063 19.856 3.15132 19.4925L9.92393 12.6556L16.6942 19.4919C17.0543 19.856 17.5269 20.0378 17.9995 20.0378C18.4722 20.0378 18.9442 19.856 19.3043 19.4925C20.0257 18.7649 20.0257 17.5852 19.3049 16.8576L12.5341 10.0207L19.3096 3.18085C20.0304 2.45382 20.0304 1.27358 19.3096 0.545949Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <slot name="content"></slot>
            </div>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
